import request from '../../utils/request'

//获取支付配置分页接口
export function getPayConfigList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/payConfig/list'
  })
}

// 新增支付配置
export function PostPayConfig(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/payConfig/submit'
  })
}

// 获得支付配置的详细信息
export function getDetailPayConfig(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/payConfig/detail/' + id
  })
}


// 删除支付配置
export function delDetailPayConfig(id: number): Promise<any> {
  return request({
    method: 'POST',
    data: {
      ids: id
    },
    type: 'params',
    url: 'manage/payConfig/remove'
  })
}
/**获得客服列表 */
export function getServiceList(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/zm-customer-service/list'
  })
}
export interface Service<T> {
  'zmId': T;
  'id'?: number;
  'name': T;
  'phone': T;
  'sex': T;
  'age': number;
}
/**新增或修改客服 */
export function depService(data: Service<string>): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/zm-customer-service/submit',
    data
  })
}
/** 删除客服 */
export function delService(id: number): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/zm-customer-service/remove?ids=' + id
  })
}

//获取体检指南的信息
export function getGuidList(): Promise<any> {
  return request({
    method: 'GET',
    type: 'params',
    url: 'manage/examinationGuide'
  })
}

//新增体检指南的信息
export function postGuidList(data: any): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/examinationGuide',
    data
  })
}
//修改体检指南的信息
export function putGuidList(data: any): Promise<any> {
  return request({
    method: 'PUT',
    url: 'manage/examinationGuide',
    data
  })
}
