import request from '../../utils/request'


//上传首页轮播图
export function uploadImgs(file: FormData): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/img/uploadImgs',
    data: file
  })
}

//删除首页轮播图
export function removeImgs(data: any): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/img/remove',
    type: 'params',
    data
  })
}

//查询首页轮播图
export function getImgs(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/img/getImgs',
  })
}

//新增首页轮播图
export function submitImgs(data: any): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/img/save',
    data
  })
}
